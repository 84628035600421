import * as React from "react";

type Props = {
  children: React.ReactNode;
  color: string;
};

function H1({ children, color }: Props) {
  return <h1 className={"font-bold text-4xl mb-10 " + color}>{children}</h1>;
}

export default H1;
