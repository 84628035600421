import * as React from "react";
import H1 from "../components/H1";
import P from "../components/P";
import Write from "../components/Write";
import observerEffect from "../utils/observerEffect";
import CardLayout1 from "../components/CardLayout1";
import { Swiper, SwiperSlide } from "swiper/swiper-react";
import { EffectCoverflow, Pagination, EffectCards } from "swiper/modules";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/effect-coverflow";
import "swiper/modules/pagination";

function Home() {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [visible2, setVisible2] = React.useState<boolean>(false);
  React.useLayoutEffect(() => {
    const ele = document.querySelector(".observe-delay") as Element;
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setTimeout(() => {
          setVisible(true);
          observer.unobserve(entry.target);
        }, 200);
      }
    });
    observer.observe(ele);
    const ele2 = document.querySelector(".observe-delay2") as Element;
    const observer2 = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setTimeout(() => {
          setVisible2(true);
          observer2.unobserve(entry.target);
        }, 200);
      }
    });
    observer2.observe(ele2);
    const elements = document.querySelectorAll(".observer-animate");
    elements.forEach((el) => {
      observerEffect(el, 100);
    });
    const specialSet = document.querySelectorAll(".special-set");
    specialSet.forEach((el, i) => {
      observerEffect(el, 200 * i);
    });
  });
  return (
    <main>
      <a
        className="fixed bottom-4 right-4 md:bottom-10 md:right-10 z-50"
        href="https://wa.me/523311146190?text=Hola, me gustaria mas informacion sobre onofriks!"
        title="link para abrir whatsapp directamente"
      >
        <img
          className="w-[50px] h-[50px] md:w-[60px] md:h-[60px]"
          src={require("../styles/whatsapp-fill.svg")}
          alt="whatsapp icono"
          loading="lazy"
        />
      </a>
      <section
        className={
          "w-full h-auto relative bg-natural-black md:bg-cover md:bg-center"
        }
      >
        <div className="w-full h-auto bg-black">
          <div className="flex flex-col w-full h-[400px] justify-center items-center">
            <span className="mx-auto max-w-lg">
              <img
                src={require("../images/Onofriks_LOGO-SOBRE-NEGRO.gif")}
                alt=""
              />
            </span>
          </div>
          <div className="w-full h-[500px] flex items-center">
            <Swiper
              className="w-full"
              modules={[EffectCoverflow, Pagination]}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              loop={true}
              effect="coverflow"
              centeredSlides={true}
              slidesPerView={screen.width < 768 ? 1.2 : 2}
              grabCursor={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
              }}
            >
              <SwiperSlide>
                <img
                  src={require("../images/playeraAzul.jpeg")}
                  alt="playera azul"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../images/bernalHoodie.jpeg")}
                  alt="hoofie negra"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../images/roiLibre.jpeg")}
                  alt="playera rosa"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../images/dobleVista_blackdiamond.jpeg")}
                  alt="playera rosa"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../images/dobleVista_somosxsiempre.jpg")}
                  alt="playera rosa"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../images/dobleVista_pinkShirt.jpeg")}
                  alt="playera rosa"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={require("../images/dobleVista_hoodieblanca.jpeg")}
                  alt="playera rosa"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className="w-full bg-mainC relative p-4 md:pb-10 md:p-1">
        <div className="w-full bg-mainC flex justify-center items-center p-3">
          <h2 className="text-lg text-white text-center">¿y quienes somos?</h2>
        </div>
        <article className="max-w-2xl mx-auto">
          <div className="observer-animate">
            <P styles="my-8">
            Somos una empresa 100% mexicana  que se especializa en la personalización de todo tipo de productos como prendas o cualquier otro tipo de publicidad.
            </P>
          </div>
          <div className="observer-animate">
            <P styles="my-8">
            Tenemos mucha experiencia y nos especializamos en las técnicas de Vinilo Textil, Sublimación y Serigrafía. 
No solo esto, también manejamos un excelente trabajo en Diseño gráfico, proporcionando así la opción de diseñar y crear!.
            </P>
          </div>
          <div className="observer-animate">
            <P styles="my-8">
            Con Onofriks ya no es necesario buscar quien te diseñe y después quien te elabore tus productos. Te ofrecemos la facilidad de hacer todo por ti!. Platicamos tu proyecto y déjanoslo en nuestras manos.
            </P>
          </div>
        </article>
      </section>
      <section className="min-h-screen w-full bg-zinc-800 relative">
        <div className="w-full h-20 bg-zinc-800 relative">
          <img
            className="w-full h-full"
            src={require("../styles/triangleAsymmetrical.svg")}
            alt=""
          />
        </div>
        <div className="h-10 mx-5 my-10 md:mx-0 observe-delay">
          <div className="max-w-3xl mx-auto">
            {visible && (
              <Write
                word="Nuestros servicios"
                styles="text-white font-bold text-4xl mb-10"
              />
            )}
          </div>
          
        </div>
        
        <div className="max-w-3xl mx-auto ">
          <article>
            <CardLayout1
              style="mb-16"
              mirror={true}
              img={require("../images/tarjeta.jpeg")}
              title="Tarjetas de presentacion"
              text=" Para tus tarjetas de presentación que deseas ofrecemos diferentes
precios y calidades, Tarjetas barnizadas o con acabado mate. O si buscas algo Premium Te
podemos ofrecer tarjetas de presentación de aluminio. "
            />
            <CardLayout1
              style="mb-16"
              img={require("../images/taza.jpeg")}
              title="Tazas"
              text="Ofrecemos la mejor calidad en tazas con la técnica de sublimación, haciendo que las
imágenes que quieras poner en tu taza nuca se borre. Las tazas personalizadas se venden desde
1pz y a partir de la 12 el precio es de mayoreo. (Diseño Incluido)"
            />
            <CardLayout1
              mirror={true}
              style="mb-16"
              img={require("../images/amatlanGasGorraNegra.jpeg")}
              title="Prendas personalizadas"
              text="Todo tipo de prendas personalizadas; Playeras, sudaderas, gorras…
todo tipo de prenda de vestir podemos desarrollar y personalizar. Desde los uniformes para tu
negocio o esa playera que tanto quisiste. (Diseño Incluido)
"
            />
          </article>
          <article className="p-5 md:p-0">
            <h2 className="font-bold text-lg text-white">
              Rotulacion con vinil textil
            </h2>
            <P styles="mb-16">
              Si deseas poner textos o imágenes en vidrios, ventanales, paredes,
              muros. O en alguna superficie, lo hacemos podemos desarrollar tu
              diseño y aplicarlo se puede hace desde 1tm cuadrado hasta lo que
              necesites.
            </P>
          </article>
        </div>
        <section className="w-full relative bg-zinc-800">
          <div className="md:py-5 md:px-8">
            <div className="md:flex md:flex-wrap w-full p-5 md:p-0">
              <div className="flex w-full h-[400px] md:w-2/5 order-1">
                <div className="w-1/2 h-full pr-4 special-set">
                  <div className="w-full h-full bg-cover bg-center bg-[url('./images/onofriksgdlshirt.jpeg')]"></div>
                </div>
                <div className="w-1/2 h-full">
                  <div className="w-full h-1/2 pb-2 special-set">
                    <div className="w-full h-full bg-cover bg-center bg-[url('./images/barbie1.jpeg')]"></div>
                  </div>

                  <div className="w-full h-1/2 pt-2 special-set">
                    <div className="w-full h-full bg-cover bg-center bg-[url('./images/mikeyMousePlayera.jpeg')]"></div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-3/5 h-full md:p-6 md:pl-6 md:pt-0 md:pr-0 order-2 special-set">
                <H1 color="text-white pt-5 md:pt-0">Diseño grafico</H1>
                <P styles="mb-6">
                  ¿Estas iniciando tu negocio? Te ayudamos a desarrollarlo.
                  Platícanos tu idea y desarrollamos tu logo y tus productos.
                  Para esto ofrecemos diferentes servicios:
                </P>
                <ul className="list-disc pl-3 text-white">
                  <li>
                    <P>
                      Manual de identidad: Donde incluye; Creación de Logotipo,
                      Colorimetría y Tipografía. Modelaje de productos deseados.{" "}
                    </P>
                  </li>
                  <li>
                    <P>Solo logotipo.</P>
                  </li>
                  <li>
                    <P>Solo diseño de productos. </P>
                  </li>
                  <li>
                    <P>Diseños de planos o Arquitectónicos</P>
                  </li>
                  <li>
                    <P>
                      Diseño abierto (Platícanos que deseas y lo hacemos por ti){" "}
                    </P>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className="max-w-3xl mx-auto">
        <article className="p-8 py-1">
            <div className="h-10 my-10 md:mx-0 observe-delay2">
              {visible2 && (
                <Write
                  word="Sucursales"
                  styles="text-white font-bold text-4xl mb-10"
                />
              )}
            </div>
            <div className="md:flex md:justify-between">
              <div className="mb-16">
                <h2 className="font-bold text-lg text-white">Fovisste</h2>
                <P styles="mb-5">Manzana 3 #229 Col.Fovisste</P>
                <a
                  href="https://goo.gl/maps/wC5sfPZHihtzDydT7"
                  className="p-2 border-4 border-alternative-mainC text-white/80 transition-all hover:bg-alternative-mainC"
                >
                  ver en maps
                </a>
              </div>
              <div className="mb-16">
                <h2 className="font-bold text-lg text-white">Miguel ramos</h2>
                <P styles="mb-5">Miguel Ramos Arizpe 767 Col.Sutaj</P>
                <a
                  href="https://goo.gl/maps/3zLrG9TkD4ooMBqaA"
                  className="p-2 border-4 border-alternative-mainC text-white/80 transition-all hover:bg-alternative-mainC"
                >
                  ver en maps
                </a>
              </div>
            </div>
          </article>
        </div>
          
        
        <div className="w-full relative ">
          <img
            className="w-full h-full"
            src={require("../styles/multiwavesWhite.svg")}
            alt="separator"
          />
        </div>
      </section>

      <section className="w-full h-auto bg-white py-10">
        <H1 color="text-black  text-center">Who trust us</H1>
        <div className="w-full md:flex justify-around">
          <div className="flex justify-center md:inline mb-8">
            <img
              className="w-[250px] h-[200px] "
              src={require("../images/logo_bernal.jpeg")}
              alt="bernal logo"
              loading="lazy"
            />
          </div>
          <div className="flex justify-center md:inline mb-8">
            <img
              className="w-[250px] h-[200px] "
              src={require("../images/logo_goodShramp.jpeg")}
              alt="good shramp logo"
              loading="lazy"
            />
          </div>
          <div className="flex justify-center md:inline mb-8">
            <img
              className="w-[250px] h-[200px] "
              src={require("../images/logo_amatlanGas.jpeg")}
              alt="amatlan gas logo"
              loading="lazy"
            />
          </div>
        </div>
        <div className="w-full md:flex justify-around">
          <div className="flex justify-center md:inline mb-8">
            <img
              className="w-[250px] h-[200px] "
              src={require("../images/logo_odl.jpeg")}
              alt="amatlan gas logo"
              loading="lazy"
            />
          </div>
          <div className="flex justify-center md:inline mb-8">
            <img
              className="w-[250px] h-[200px] "
              src={require("../images/logo_onofreDesign.jpeg")}
              alt="amatlan gas logo"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
