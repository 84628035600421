import * as React from "react";

type Props = {
  word: string;
  styles: string;
};

function Write({ word, styles }: Props) {
  let [writing, setWriting] = React.useState<string>("");
  let [index, setIndex] = React.useState<number>(0);
  React.useEffect(() => {
    const wordArr = word.split("");
    const interval = setInterval(() => {
      if (wordArr.length > index) {
        setWriting((writing += wordArr[index]));
        setIndex((index += 1));
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);
  return <h1 className={styles}>{writing}</h1>;
}

export default Write;
