import * as React from "react";
import P from "./P";
type Props = {
  img: string;
  title: string;
  text: string;
  style?: string;
  mirror?: boolean;
};
function CardLayout1({ img, title, text, style, mirror }: Props) {
  return (
    <div className={"p-5 md:p-0 w-full md:h-44 md:flex " + style}>
      <div
        className={
          mirror
            ? "w-full h-64 md:w-2/5 md:h-auto bg-cover bg-center order-2"
            : `w-full h-64 md:w-2/5 md:h-auto bg-cover bg-center order-1`
        }
        style={{
          backgroundImage: `url(${img})`,
        }}
      >
        {/* <img src={img} className=" object-cover" alt=''/> */}
      </div>

      <div
        className={
          mirror
            ? "pt-5 md:pr-5 md:pt-0 w-full order-1"
            : "pt-5 md:pt-0 md:pl-5 w-full order-2"
        }
      >
        <h2 className="font-bold text-lg text-white">{title}</h2>
        <P>{text}</P>
      </div>
    </div>
  );
}

export default CardLayout1;
