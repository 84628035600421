function callback(timeout?: number | null | undefined) {
  return (entries: any, observer: IntersectionObserver) => {
    const entry = entries[0];
    const target = entry.target;
    target.classList.add(
      "opacity-0",
      "translate-y-4",
      "transition",
      "duration-700"
    );
    if (entry.isIntersecting) {
      if (timeout) {
        setTimeout(() => {
          target.classList.remove("opacity-0");
          target.classList.replace("translate-y-4", "translate-y-0");
          observer.unobserve(target);
        }, timeout);
      } else {
        target.classList.remove("opacity-0");
        target.classList.replace("translate-y-4", "translate-y-0");
        observer.unobserve(target);
      }
    }
  };
}
export default function observerEffect(element: any, timeout?: number) {
  const observer = new IntersectionObserver(callback(timeout), {
    threshold: 0.75,
  });
  observer.observe(element);
}
