import * as React from "react";

type Props = {
  children: React.ReactNode;
  styles?: string;
};

function P({ children, styles }: Props): JSX.Element {
  return (
    <p
      className={`text-white/80 text-lg leading-loose first-letter:capitalize ${styles}`}
    >
      {children}
    </p>
  );
}

export default P;
